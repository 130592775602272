.menu {
    > a {
         margin-left: 50px;
         >span {
             font-size: 20px;
         }
         color: black;
    }
 
    >a:hover {
       color: #00aaff;
       text-decoration: underline;
       text-decoration-color: #00aaff;
    }
 }

 .menu_context {
    height: 80px;
    text-align: center;
    align-items: center;

}