
.item_title {
    font-size: 30px;
    text-align: center;
    color: #5373C5;;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
}


.solution_item_1 {
    height: 600px;
    background: #fbfcfc;
    display: flex;
    justify-content: center;
    text-align: center;
}


.solution_item_2 {
    height: 600px;
    background: #f1f1f2;
    display: flex;
    justify-content: center;
    text-align: center;
}

.solution_item_title{
    width: 400px;
    > span {
        display: flex;
        margin-top: 130px;
        font-size: 20px;
        color: #03225f;
    }
}
