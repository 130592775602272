.top_part {
    width: 100%;
    height: 600px;
}


.top_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    >img {
        width: 600px;
        height: 500px;
        margin-right: 200px;
    }

    >span {

        float: right;
        margin-left: 100px;
        font-size: 60px;
        font-weight: bolder;
        text-shadow: 1px 1px 2px rgba(223, 0, 202, 0.2);
        background: linear-gradient(to right,#23549e, #ec6176);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}


.part_title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: bolder;
}


.introduce_01 {
    height: 660px;
    width: 100%;
    >img {
        pointer-events: none;
        width: 100%;
        height: 100%;
    }
}


.introduce_02 {
    height: 500px;
    width: 100%;
    >img {
        pointer-events: none;
        width: 100%;
        height: 100%;
    }
}