.footer {
    width: 100%;
    height: 200px;
    background-color: #1f1f1f;
    position: relative;
    margin-top: 1px;
}

.footer_all {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.contact_item {
    text-align: left;
    >p {
        color: #ffffff;
    }

}


.contact_copyright {
    position: absolute;
    bottom: 0;
    >p {
        color: #ffffff;
    }
}

a {
    color: #ffffff;
}