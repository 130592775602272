.base_top {
    width: 100%;
    background-image: url('../../../public/images/bg-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
}

.bg_content {
    width: 100%;
    height: 500px;
    text-align: center;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.text_detail {
    height: 500px;
    width: 50%;
}

.text_detail p:first-child {
    margin-top: 90px;
    background: linear-gradient(to right, #00aaff, #01143a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(223, 0, 202, 0.2);
    text-size-adjust: auto;
}

.text_detail p:last-child {
    font-size: 20px;
    color: #03225f;
    text-align: left;
    margin: 0 20% 0 20%;
}


.text_detail_right {
    
    > img {
        width: 100%;
        height: 100%;
    }
}


.introduce_code {
    text-align: center;
    height: 600px;
    >p {
        color: #03225f;
        font-size: 30px;
        font-weight: bolder;
    }

}


.item_tab {
    display: flex;
    justify-content: center;
    list-style-type: none;
    >li {
        width: 240px;
        height: 300px;
        box-shadow: 0px 0px 20px 0px rgba(10,154,254,0.25);
        border-radius: 12px;
        padding: 30px 10px 0 10px;
        margin: 25px;
        float: left;

        >:first-child {
            color: #00aaff;
        }

        >:nth-child(2) {
            color: #03225f;
            font-weight: bold;
            font-size: 20px;
        }

        >:last-child{
            color: #03225f;
            line-height: 25px;
            margin: 0 8px 0 8px;
            text-align: left;
        }
    }

}


.support_type {

    height: 600px;
    text-align: center;
    >:first-child {
        font-size: 30px;
        font-weight: bolder;
        color: #03225f;
    }

    >:nth-child(2) {
        color: #D2D2D2;
        margin-top: 20px;
    }

    >ul {
        margin-top: 20px;
        list-style: none;
        display: flex;
        justify-content: center;
        >li {

            >div {
                
                width: 180px;
                height: 160px;
                box-shadow: 0px 0px 20px 0px rgba(10,154,254,0.25);
                border-radius: 12px;
                padding: 20px;
                margin: 0 20px 0 20px;
                margin-bottom: 25px;
                float: left;
                >:first-child {
                    color: #00aaff;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;
                }
        
                >:last-child {
                    color: #03225f;
                    font-weight: bold;
                    font-size: 20px;
                }

            }
        }
    }
}


.dependence_introduce {
    margin: 0 auto;
    align-items: center;
    text-align: center;
    width: 60%;
    height: 1800px;

    >span {
        font-size: 30px;
        font-weight: bolder;
        color: #03225f;
    }

    >div {
        padding: 30px;
        display: flex;
        justify-content: center;
        margin-top: 60px;
        >img { 
            width: 400px;
            height: 400px;
            padding: 30px;
        }

        >span {
            width: 400px;
            color: #03225f;
            font-size: 18px;
            padding: 35px;
            margin-top: 30px;
            line-height: 36px;
            text-align: left;
        }

    }


}
